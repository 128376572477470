import React, { useState } from "react";
import styled from "styled-components";
// components
import Modal from "../../components/Modal";
import MediaLibrary from "../../components/MediaLibrary";
import Env from "../../Env";
import { camelCaseToNormalText } from "../../helpers/helpers";

import { JSONSchema7 } from "json-schema";

interface Props {
  value: string;
  onChange: (val: string) => void;
  label: string;
  schema: JSONSchema7;
}

const Container = styled.div`
  background-color: #fff;
  border: 2px dashed #bcbec0;
  border-radius: 10px;
  padding: 0;
  margin: 20px auto;
  height: 200px;
  width: 250px;
  cursor: pointer;
  text-align: center;
  height: 100%;
`;

const ImgPreview = styled.img`
  width: 100%;
`;

const DropInfo = styled.div`
  padding: 10px;
  color: #55616b;
`;

const FileSelector: React.FC<Props> = ({
  value,
  onChange,
  label,
  schema: { description },
}) => {
  const [open, setOpen] = useState(false);

  const fileSelected = (fileName: string) => {
    const fullFilePath = `${Env.cdnPreview}/${fileName}`;
    onChange(fullFilePath);
    setOpen(false);
  };
  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <MediaLibrary onSelectFile={fileSelected} />
      </Modal>

      <h4 id="label">{camelCaseToNormalText(label)}</h4>
      <p>{description}</p>
      <Container onClick={() => setOpen(true)}>
        <DropInfo id="info">
          <div id="info">
            <img src="/assets/svg/click.svg" alt="" />{" "}
            {value ? (
              <p>Click to add a new image</p>
            ) : (
              <p>Click to add an image</p>
            )}
          </div>
          {value && <ImgPreview src={value} alt="" />}
        </DropInfo>
      </Container>
    </>
  );
};

export default FileSelector;
