import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInModal } from "../common/styles";

interface Props {
  open: boolean;
  setOpen: Function;
}

export const slideInRight = keyframes`
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
`;

const ModalBackDrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
  display: grid;
  place-items: center;
  animation: ${fadeInModal} 0.35s ease-in-out 0s forwards 1;
`;

const ModalBodyContainer = styled.div`
  background: white;
  padding: 1rem 0 1rem 0;
  animation: ${slideInRight} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: relative;
  width: 70%;
  height: 100%;
  min-height: 50vh;
  position: fixed;
  z-index: 1001;
  right: 0;
  bottom: 0;
  align-items: center;
  background-color: #fff;
  text-align: center;
  box-shadow: -1px 0px 1px 1px #f2f2f2;
`;

const Modal: React.FC<Props> = ({ open, setOpen, children }) => {
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      function handleKeyPressOutside(event: any) {
        if (event.key === "Escape") {
          setOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyPressOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyPressOutside);
        setOpen(false);
      };
    }, [ref]);
  }

  if (!open) return null;
  return (
    <ModalBackDrop>
      <ModalBodyContainer ref={modalRef}>{children}</ModalBodyContainer>
    </ModalBackDrop>
  );
};

export default Modal;
