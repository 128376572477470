export function isFile(path: string): boolean {
  if (!path) return false;
  const filePaths = [".png", ".jpg", ".jpeg", ".svg", ".gif", ".webp"];
  if (filePaths.some((v) => path.includes(v))) {
    return true;
  }

  return false;
}

export function camelCaseToNormalText(text?: string) {
  if (!text) return "";

  return (
    text
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}
