import React, { useEffect, useState } from "react";
import { Button } from "../../common/styles";
import styled from "styled-components";

const AuthContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-areas:
    "content-header content-header content-header"
    ". content-middle ."
    "content-footer content-footer content-footer";

  grid-template-rows:
    minmax(400px, max-content) minmax(min-content, max-content)
    min-content auto;
  grid-template-columns: 1fr minmax(min-content, 450px) 1fr;
  margin: 0 auto;
  max-width: 900px;
  text-align: center;
`;

const TitleContainer = styled.div`
  grid-area: content-header;
  align-self: flex-end;
  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 1.8rem;

    span {
      color: #34bebd;
    }
  }
`;

const PageContainer = styled.div``;

const BodyContainer = styled.div`
  grid-area: content-middle;
`;

const LogoContainer = styled.img`
  min-width: 130px;
  margin-right: 10px;
`;

interface Props {
  handleLogin: () => void;
}

const Auth: React.FC<Props> = ({ handleLogin }) => {
  return (
    <PageContainer>
      <AuthContainer>
        <TitleContainer>
          <h1>
            <LogoContainer src="/assets/svg/vouchlogo.svg" />
            Utility Dashboard | <span>Login</span>
          </h1>
        </TitleContainer>
        <BodyContainer>
          <Button onClick={() => handleLogin()}>Login/Sign up</Button>
        </BodyContainer>
      </AuthContainer>
    </PageContainer>
  );
};

export default Auth;
