import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
// components
import LineChart from "../../components/ActivityLogger/LineChart";
import SearchPanel from "../../components/SearchPanel";
// types
import { ActivityLog } from "../../types/ActivityLogger";

interface Props {}

const Container = styled.div``;

const Header = styled.div`
  margin: 0 5px;
`;

const ActivityLoggerDashboard: React.FC<Props> = (props) => {
  const [term, setTerm] = useState("");
  const [view, setView] = useState<"LATEST" | "SEARCH_RESULTS">("LATEST");
  const [searchResults, setSearchResults] = useState<ActivityLog[]>([]);

  useEffect(() => {
    // new search results so change the view
    if (searchResults.length > 0) {
      setView("SEARCH_RESULTS");
    }
  }, [searchResults]);

  return (
    <Container>
      <Header>
        <h1>Activity Logger</h1>
        <SearchPanel
          searchTerm={term}
          setSearchTerm={setTerm}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
      </Header>
      <LineChart view={view} setView={setView} searchResults={searchResults} />
    </Container>
  );
};

export default ActivityLoggerDashboard;
