import styled, { keyframes } from "styled-components";

export interface IBannerBackground {
  bannerUrl: string;
}

export const PageWrapper = styled.div<{ center?: boolean }>`
  max-width: 1200px;
  margin: ${(props) => (props.center === true ? "0 auto" : "0")};
  text-align: left;
  padding: 0 20px;
  width: 100%;
`;

export const Colours = {
  teal: "#34bebd",
  blue: "#082842",
};

/* ANIMATIONS */
export const wiggle = keyframes`
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

export const fadeIn = keyframes`
  from {ha
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const slideInTop = keyframes`
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

export const fadeInModal = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.45);
  }
`;

export const BannerBackground = styled.div<IBannerBackground>`
  &:after {
    content: "";
    // prettier-ignore
    // -webkit-mask-image: linear-gradient( rgba(0,0,0,1) 25%,rgba(0,0,0,0.8) 70%, rgba(0,0,0,0) 100%);
    // mask-image: linear-gradient(
    //   rgba(0, 0, 0, 1) 25%,
    //   rgba(0, 0, 0, 0.8) 70%,
    //   rgba(0, 0, 0, 0) 100%
    // );
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p: IBannerBackground) => (p.bannerUrl ? p.bannerUrl : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    position: fixed;
    background-position: top center;

    @media (max-width: 320px) {
      height: 300px;
    }
  }

  & > * {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
  & > #footer {
    z-index: 0;
  }
`;

export const Button = styled.button<IButton>`
  background-color: #34bebd;
  margin: 10px;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #34bebd;
  width: 150px;
  height: 60px;
  letter-spacing: 0.23px;
  color: white;
  font: bold 15px Lato, sans-serif;
  outline: none;

  &:disabled {
    background: #f2f2f2;
    border-color: #f2f2f2;
    color: #082842;
    pointer-events: none;
  }
`;

export const AlternateButton = styled(Button)`
  background: none;
  border-color: #082842;
  color: #082842;
`;

export const CTA = styled(Button)`
  background: none;
  border-color: white;
  color: white;
`;

export const SecondaryButton = styled(Button)`
  background-color: transparent;
`;

export const InputWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: block;
`;

interface IButton {
  disabled?: boolean;
  alternate?: boolean;
}
