import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
// queries
import { useActivities } from "../../network/graphql";
// types
import { ActivityLog } from "../../types/ActivityLogger";
import { AlternateButton } from "../../common/styles";

interface Props {
  view: "LATEST" | "SEARCH_RESULTS";
  setView: (view: "LATEST" | "SEARCH_RESULTS") => void;
  searchResults: ActivityLog[];
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1.75fr 1fr;
`;

const Activity = styled.div`
  text-transform: capitalize;
  padding: 10px;

  h3 {
    color: #34bebd;
  }
`;

const ActivityContainer = styled.div`
  #results-container {
    max-height: 500px;
    overflow-y: auto;
  }

  #back:hover {
    cursor: pointer;
  }

  #title {
    text-align: right;
    margin: 0 10px;
  }
`;

const MainLineChart: React.FC<Props> = ({ setView, view, searchResults }) => {
  const { data, isLoading, isError } = useActivities();

  function formatXAxis(tickItem: any) {
    // If using moment.js
    return moment(tickItem).format("Do MMM YY");
  }

  function formatActivityDate(date: any) {
    // If using moment.js
    return moment(date).format("dddd Do MMMM, YYYY");
  }

  console.log(data);

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Ooops there was an error</div>;

  return (
    <Container>
      <LineChart
        width={800}
        height={600}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="createdAt" tickFormatter={formatXAxis} />
        <YAxis dataKey="customerName" />
        <Tooltip />
        <Line
          connectNulls
          type="monotone"
          dataKey="createdAt"
          stroke="#34bebd"
          fill="#34bebd"
        />
      </LineChart>
      {data && view === "LATEST" && (
        <ActivityContainer>
          <div className="side-by-side-flex-end">
            <h3 id="title">Latest Results</h3>
          </div>
          <div id="results-container">
            {data.splice(0, 20).map((activity: ActivityLog) => (
              <Activity>
                <h4>
                  {activity.payload.details !== activity.payload.message &&
                    activity.payload.details}{" "}
                  {activity.payload.message}{" "}
                </h4>
                <div className="side-by-side-flex">
                  <p>{formatActivityDate(activity.createdAt)}</p>
                  {activity.customerName && (
                    <h3 id="customerName">{activity.customerName}</h3>
                  )}
                </div>
              </Activity>
            ))}
          </div>
        </ActivityContainer>
      )}
      {searchResults && view === "SEARCH_RESULTS" && (
        <ActivityContainer>
          <div className="side-by-side-flex">
            <img
              onClick={() => setView("LATEST")}
              id="back"
              src="/assets/svg/back.svg"
            />
            <h3 id="title">Search Results</h3>
          </div>
          <p>{searchResults.length} Matching Results Found</p>
          <div id="results-container">
            {searchResults.map((activity: ActivityLog) => (
              <Activity>
                <h4>
                  {activity.payload.details !== activity.payload.message &&
                    activity.payload.details}{" "}
                  {activity.payload.message}{" "}
                </h4>
                <div className="side-by-side-flex">
                  <p>{formatActivityDate(activity.createdAt)}</p>
                  {activity.customerName && (
                    <h3 id="customerName">{activity.customerName}</h3>
                  )}
                </div>
                {activity.payload.logicAppRunId && (
                  <AlternateButton
                    onClick={() =>
                      window.open(
                        `https://portal.azure.com/#@vouchoneoutlook.onmicrosoft.com/resource/subscriptions/2c7897c6-c99b-497f-bba1-b2d522a6d295/resourceGroups/v2/providers/Microsoft.Logic/workflows/${activity.payload.sourceApplication}`,
                        "_blank"
                      )
                    }
                  >
                    View Run
                  </AlternateButton>
                )}
              </Activity>
            ))}
          </div>
        </ActivityContainer>
      )}
    </Container>
  );
};

export default MainLineChart;
