import React, { useState, useContext } from "react";

import { Deal } from "../types/Store";
import CTAButtons from "./CTAButtons";
// components
import styled from "styled-components";
// helpers
// components
import Modal from "../components/Modal";

interface Props {
  deal: Deal;
  department: string;
  store: string;
  singleDeal?: boolean;
  storeId?: string;
}

const DealCardContainer = styled.div<{ singleDeal: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: white;
  max-width: 450px;
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    cursor: ${(p) => (p.singleDeal ? "" : "pointer")};
  }

  @media (max-width: 600px) {
    max-width: none;
  }
`;

const PriceAndTerm = styled.div`
  h2 {
    font-family: "Montserrat", sans-serif;
    color: #34bebd;
    border-radius: 10px;
    padding: 5px 0;
    margin: 0;
    line-height: 2rem;
    font-size: 2rem;
  }

  span {
    font-size: 1rem;
    color: #082842;
    font-weight: 600;
  }
`;

const SmallPrint = styled.p`
  font-size: 10px;
  padding: 20px;
  margin: 0;
  color: white;
  text-align: justify;
  padding-top: 0;
`;

const DealTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
`;

const DealDesc = styled.p``;

const DealBody = styled.div`
  padding: 0 20px 20px 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const DealDetails = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
`;

const DealTags = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  justify-content: flex-end;
  align-items: flex-end;
`;

const DealTag = styled.h5`
  border: 1px solid #34bebd;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  margin: 0;
  color: #082842;
`;

const CTAAndSmallPrint = styled.div`
  background: #082842;
  border-radius: 0 0 10px 10px;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const DealCard: React.FC<Props> = ({
  deal,
  store,
  department,
  singleDeal,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [isCallback, setIsCallback] = useState(true);
  const [revealNumber, setRevealNumber] = useState(false);

  function handleDealSelect(callback: boolean) {
    if (callback) {
      setIsCallback(true);
    } else {
      setIsCallback(false);
    }
    setOpen(true);
  }
  interface Price {
    amount: string;
    currency: string;
    term: string;
  }
  function formatCurrency(price: Price): string {
    if (!price.amount) return "";

    let symbol: string = "£";

    switch (price.currency) {
      case "":
        symbol = "";
        break;
      case "GBP":
        symbol = "£";
        break;
      case "USD":
        symbol = "$";
        break;
    }

    return `${symbol}${price.amount}`;
  }

  return (
    <>
      <DealCardContainer id={deal.id} singleDeal>
        <DealBody id={"deal-body-" + deal.id}>
          <DealTitle>{deal.dealName}</DealTitle>
          {deal.description && <DealDesc>{deal.description}</DealDesc>}
          <DealDetails>
            <PriceAndTerm>
              {deal.price && (
                <PriceAndTerm>
                  <h2>
                    {deal.price.currency
                      ? formatCurrency(deal.price)
                      : deal.price.amount}
                  </h2>
                  <span>{deal.price.term}</span>
                </PriceAndTerm>
              )}
            </PriceAndTerm>
            <DealTags>
              {deal.tags && deal.tags.map((tag) => <DealTag>{tag}</DealTag>)}
            </DealTags>
          </DealDetails>
        </DealBody>
        <CTAAndSmallPrint>
          <CTAButtons
            deal={deal}
            handleDealSelect={handleDealSelect}
            revealNumber={revealNumber}
            setOpen={setOpen}
          />

          {deal.smallPrint && <SmallPrint>{deal.smallPrint}</SmallPrint>}
        </CTAAndSmallPrint>
      </DealCardContainer>
    </>
  );
};

export default DealCard;
