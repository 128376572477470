import React, { useEffect, useState } from "react";
import styled from "styled-components";
// components
import StoreList from "../../components/StoreList";
import StoreGrid from "../../components/StoreGrid";
// types
import { Store, Department } from "../../types/Store";

interface Props {
  setView: Function;
  setEditStore: Function;
}

const DashboardContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: -webkit-fill-available;
`;

const ControlsLayout = styled.div`
  max-width: 1200px;
  margin: 10px auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .layout-selector {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin: 0 5px;
    width: 100px;
  }

  .layout-selector:hover {
    cursor: pointer;
  }

  .layout-selector img {
    max-width: 24px;
  }
`;

const Dashboard: React.FC<Props> = ({ setView, setEditStore }) => {
  const [stores, setStores] = useState([]);
  const [layout, setLayout] = useState<"GRID" | "LIST">("GRID");

  function startEditing(storeId: string) {
    if (!stores) return;

    const foundStore: Store[] = stores.filter(({ id }) => {
      return id === storeId.toLowerCase();
    });
    const [store] = foundStore;

    if (!store.departments) {
      store.departments = [];
      const newDepartment: Department = {
        departmentName: "New Department",
        departmentDesc: "New Department Desc",
        deals: [],
        departmentShortDesc: "Short Desc",
        callbackUrl: "",
        departmentBanner: "",
        departmentIcon: "",
      };
      store.departments.push(newDepartment);
    }
    setEditStore(store);
    setView("builder");
  }

  function toggleVisibility(storeId: string) {
    if (!stores) return;

    const foundStore: Store[] = stores.filter(({ id }) => {
      return id === storeId.toLowerCase();
    });
    const [store] = foundStore;

    const reqBody = {
      query: `mutation{
        toggleVisibility(storeId: "${store.id}" storeName: "${store.storeName}")
      }`,
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then(({ data }) => {
        console.log(data);
        buildDashboard();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function buildDashboard() {
    const reqBody = {
      query: `query{
        getDashboardData{
          id
          locked
          logoUrl
          smallBanner
          storeTermsAndCond
          storeName
          description
          departments{
            departmentName
            departmentDesc
            departmentShortDesc
            departmentBanner
            departmentIcon
            callbackUrl
            customDepartment{
              actionText
            }
            deals{
              id
              dealName
              tags
              description
              smallPrint
              price{
                amount
                currency
                term
              }
              icon
              url
              startDate
              endDate
            }
          }
        }
      }`,
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then(({ data }) => {
        if (data.getDashboardData && data.getDashboardData.length > 0) {
          setStores(data.getDashboardData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    buildDashboard();
  }, []);
  return (
    <DashboardContainer>
      <ControlsLayout>
        <div className="layout-selector" onClick={() => setLayout("GRID")}>
          <img src="/assets/svg/grid.svg" /> <span>Grid</span>
        </div>
        <div className="layout-selector" onClick={() => setLayout("LIST")}>
          <img src="/assets/svg/list.svg" /> <span>List</span>
        </div>
      </ControlsLayout>
      {layout === "LIST" && (
        <StoreList
          stores={stores}
          startEditing={startEditing}
          toggleVisibility={toggleVisibility}
        />
      )}

      {layout === "GRID" && (
        <StoreGrid
          stores={stores}
          startEditing={startEditing}
          toggleVisibility={toggleVisibility}
        />
      )}
    </DashboardContainer>
  );
};

export default Dashboard;
