import React from "react";
import styled from "styled-components";
import { Store, Department } from "../types/Store";
import { AlternateButton, Button } from "../common/styles";

interface Props {
  stores: Store[];
  startEditing: Function;
  toggleVisibility: Function;
}

const List = styled.div`
  margin: 30px auto;
  width: 90%;
  background: #fff;
  max-width: 1200px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 0 20px rgb(0 0 0 / 10%);
`;

const ListItem = styled.div<{ header?: boolean }>`
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1fr 200px 400px;
  grid-gap: 10px;
  background: ${(props) => (props.header ? "#f2f2f2" : "")};
  h2 {
    font-weight: ${(props) => (props.header ? "bold" : 100)};
  }
`;

const ListCell = styled.h4`
  text-align: left;
  font-size: 1.2rem;
`;

const StoreItem: React.FC<{
  store: Store;
  startEditing: Function;
  toggleVisibility: Function;
}> = ({ store, startEditing, toggleVisibility }) => {
  function formatDepartments(deps: Department[]): string {
    let depNames: string[] = [];
    if (!deps) return "No Departments";
    deps.forEach((d: Department) => {
      depNames.push(d.departmentName);
    });

    return `${depNames.join(", ")}`;
  }
  return (
    <ListItem>
      <ListCell>{store.storeName}</ListCell>
      <ListCell>{formatDepartments(store.departments)}</ListCell>
      <ListCell>{store.locked ? "Closed" : "Open"}</ListCell>
      <ListCell>
        <AlternateButton onClick={() => startEditing(store.id)}>
          Edit
        </AlternateButton>
        <Button
          disabled={!store.departments}
          onClick={() => toggleVisibility(store.id)}
        >
          {store.locked ? "Make Public" : "Make Private"}
        </Button>
      </ListCell>
    </ListItem>
  );
};

const StoreList: React.FC<Props> = ({
  stores,
  startEditing,
  toggleVisibility,
}) => {
  return (
    <List>
      <ListItem header>
        <ListCell>Store Name</ListCell>
        <ListCell>Departments</ListCell>
        <ListCell>Published</ListCell>
      </ListItem>
      {stores.map((str) => (
        <StoreItem
          key={str.id}
          store={str}
          startEditing={startEditing}
          toggleVisibility={toggleVisibility}
        />
      ))}
    </List>
  );
};

export default StoreList;
