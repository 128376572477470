import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../common/styles";
import TextInput from "./input/TextInput";
import UrlInput from "./input/UrlInput";

const NewShopContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
  display: grid;
  height: 100%;
  grid-template-rows: 150px auto 100px; ;
`;

const Header = styled.div``;

const Body = styled.div``;

const Footer = styled.div`
  text-align: right;
`;

interface Props {
  newStore: IStore;
  setNewStore: Function;
  onStoreCreated: () => void;
}

interface IStore {
  id: string;
  storeName: string;
}

const NewShop: React.FC<Props> = ({
  newStore,
  setNewStore,
  onStoreCreated,
}) => {
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const { id, storeName } = newStore;
    if (id.trim() !== "" && storeName.trim() !== "") {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [newStore]);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    const currStore = { ...newStore };
    switch (name) {
      case "id":
        currStore.id = value;
        setNewStore(currStore);
        break;
      case "storeName":
        currStore.storeName = value;
        setNewStore(currStore);
        break;
    }
  }

  function handleSubmit() {
    const reqBody = {
      query: `mutation($newStore: NewStoreInput){
            createStore(newStore: $newStore){
              storeName
            }
          }`,
      variables: { newStore },
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        onStoreCreated();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <NewShopContainer>
      <Header>
        <h1>
          New{" "}
          <span style={{ color: "#34bebd" }}>Vouch Home Move Assistant</span>{" "}
          Store
        </h1>
        <p>Lets get started by naming the store. </p>
      </Header>
      <Body>
        <UrlInput
          preface="store.vouch.co.uk/"
          value={newStore.id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e)
          }
          type="text"
          label="Store URL"
          name="id"
        />
        <TextInput
          value={newStore.storeName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e)
          }
          type="text"
          label="Store Name"
          name="storeName"
        />
      </Body>

      <Footer>
        <Button disabled={!valid} onClick={handleSubmit}>
          Create
        </Button>
      </Footer>
    </NewShopContainer>
  );
};

export default NewShop;
