import React, { useState } from "react";
import styled from "styled-components";
//import { BannerBackground, IBannerBackground } from "../common/styles";
// types
import { Store, Deal, Department } from "../types/Store";
// components
import DealCard from "./DealCard";
import Footer from "./Footer";
import StoreHeader from "./StoreHeader";

interface Props {
  store: Store;
}

interface IBannerBackground {
  smallBanner: string;
}

const StoreContainer = styled.div<IBannerBackground>`
  &:after {
    content: "";
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p: IBannerBackground) => (p.smallBanner ? p.smallBanner : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    right: 0;
    top: 0;
    width: 70%;
    height: 500px;
    position: fixed;
    background-position: top center;

    @media (max-width: 320px) {
      height: 300px;
    }
  }
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  z-index: 1;
  position: relative;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  h1 {
    color: white;
    padding: 20px;
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

const BlurbContainer = styled.div``;

const DealsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: flex-start;
  margin: 25px 0;
  grid-gap: 20px;

  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const StoreSkeletonContainer = styled.div``;

const DepartmentSelector = styled.select`
  position: fixed;
  top: 10px;
  right: 10px;
  background: white;
  padding: 15px;
  z-index: 99;
  border-radius: 5px;
  outline: none;
  border: none;

  option {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
`;

const StoreSkeleton: React.FC<Props> = ({ store }) => {
  const [depIndex, setDepIndex] = useState(0);

  function handleDepChange({ target }: any) {
    setDepIndex(target.value);
  }
  return (
    <>
      <DepartmentSelector onChange={(e: any) => handleDepChange(e)}>
        {store.departments.map((dep: Department, index: number) => (
          <option value={index}>{dep.departmentName}</option>
        ))}
      </DepartmentSelector>
      <StoreSkeletonContainer>
        <StoreContainer smallBanner={store.smallBanner}>
          <MainStoreBanner>
            <StoreHeader
              storeLogo={store.logoUrl}
              department={store.departments[depIndex].departmentName}
            />
          </MainStoreBanner>
          <BlurbContainer>
            <DealsContainer>
              {store.departments[depIndex].deals.map(
                (deal: Deal, index: number) => (
                  <DealCard
                    deal={deal}
                    store={store.storeName}
                    department={store.departmentName}
                  />
                )
              )}
            </DealsContainer>
          </BlurbContainer>
          <Footer>
            <SmallPrint>{store.storeTermsAndCond}</SmallPrint>
          </Footer>
        </StoreContainer>
      </StoreSkeletonContainer>
    </>
  );
};

export default StoreSkeleton;
