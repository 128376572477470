import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

function initAzureStorage() {
  const blobServiceClient = new BlobServiceClient(
    `${process.env.REACT_APP_BLOB_SAS}`
  );
  return blobServiceClient;
}

const blobServiceClient = initAzureStorage();

async function getBlobsFromContainer(
  client: ContainerClient,
  prefix: string = ""
) {
  const rawContentBlobs = client.listBlobsByHierarchy("/", {
    prefix,
  });
  const blobsFound = [];
  for await (const blob of rawContentBlobs) {
    blobsFound.push(blob);
  }

  return [...blobsFound];
}

export async function getAllBlobs(prefix: string = "") {
  console.log("fetching...");
  const containerClient = blobServiceClient.getContainerClient(
    `${process.env.REACT_APP_AZURE_TEMPLATE_CONTAINER}`
  );

  const filteredContentBlobs = await getBlobsFromContainer(
    containerClient,
    prefix
  );

  return {
    content: [...filteredContentBlobs],
  };
}

export async function handleUpload(
  e: any,
  typeToUpload: "base" | "content" | "footer" | "header",
  fileName = ""
) {
  console.log(e);
  let uploadedFile = e.target.files[0];
  const containerClient = blobServiceClient.getContainerClient(
    `${process.env.REACT_APP_AZURE_TEMPLATE_CONTAINER}`
  );

  /* 
    if header or footer, save as header.md or footer.md
  */
  if (typeToUpload === "header" || typeToUpload === "footer") {
    uploadedFile = new File([uploadedFile], `${typeToUpload}.md`, {
      type: uploadedFile.type,
    });
  } else {
    /* 
      if type is content, use 3rd param file name
    */
    if (typeToUpload === "content" && fileName.trim() !== "") {
      uploadedFile = new File([uploadedFile], `${fileName}`, {
        type: uploadedFile.type,
      });
    }

    /* 
        if no md file extension, add it
      */
    if (!uploadedFile.name.includes(".md")) {
      uploadedFile = new File([uploadedFile], `${uploadedFile.name}.md`, {
        type: uploadedFile.type,
      });
    }
  }

  let blobClientUrl = "";
  if (typeToUpload === "base") {
    blobClientUrl = `${process.env.REACT_APP_CUSTOMER_ID}/Emails/Layouts/${uploadedFile.name}`;
  } else if (typeToUpload === "content") {
    blobClientUrl = `${process.env.REACT_APP_CUSTOMER_ID}/Emails/Subjects/${uploadedFile.name}`;
  } else if (typeToUpload === "header") {
    blobClientUrl = `${process.env.REACT_APP_CUSTOMER_ID}/Emails/Layouts/base/${uploadedFile.name}`;
  } else if (typeToUpload === "footer") {
    blobClientUrl = `${process.env.REACT_APP_CUSTOMER_ID}/Emails/Layouts/base/${uploadedFile.name}`;
  }
  const blockBlobClient = containerClient.getBlockBlobClient(blobClientUrl);
  const data = await blockBlobClient.uploadData(uploadedFile);
  return data;
}

export async function sendEmailTemplate(emailDetails: any) {
  const sendInfo = {
    context: {
      recipient: {
        name: emailDetails.tenantName,
        reference: "VCHF74-20345",
        link: "https://bot.vouch.co.uk",
      },
      asset: {
        type: "property",
      },
      customer: {
        name: emailDetails.agentName,
      },
    },
    from: "do_not_reply@mail.vouch.co.uk",
    subject: emailDetails.subject,
    template: emailDetails.template,
    to: [
      {
        email: emailDetails.to,
        name: "test",
      },
    ],
    type: "vouch",
    version: `${process.env.REACT_APP_CUSTOMER_ID}`,
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_SEND_EMAIL}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY_EMAIL}`,
      },
      method: "POST",
      body: JSON.stringify(sendInfo),
    });
    if (res.ok) {
      return 200;
    } else {
      return 400;
    }
  } catch (e) {
    return 400;
  }
}
