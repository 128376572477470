import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { QueryClient, QueryClientProvider } from "react-query";

const config: Configuration = {
  auth: {
    clientId: "a8581991-dc2c-40ec-b42b-b8c0428c3e68",
    authority:
      "https://vouchauth.b2clogin.com/vouchauth.onmicrosoft.com/B2C_1_sign_up_sign_in",
    knownAuthorities: ["vouchauth.b2clogin.com"],
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : "https://vouchauth.b2clogin.com/vouchauth.onmicrosoft.com/B2C_1_sign_up_sign_in/oauth2/v2.0/logout?post_logout_redirect_uri=https://agent.vouch.co.uk",
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: "localStorage",
  },
};

const pca = new PublicClientApplication(config);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
