import React from "react";
import styled from "styled-components";
import { Store, Department } from "../types/Store";
import {
  AlternateButton as BaseAlternateButton,
  Button as BaseButton,
} from "../common/styles";

interface Props {
  stores: Store[];
  startEditing: Function;
  toggleVisibility: Function;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding-bottom: 50px;
`;

const CardContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 0 20px rgb(0 0 0 / 10%);
  background: #fff;
  padding: 25px;

  #top-banner {
    min-height: 70px;
  }

  #logo-url {
    max-width: 75px;
  }
`;

const DepartmentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  #department {
    font-weight: bold;
    color: #34bebd;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin: 30px auto;
`;

const StoreControls = styled.div``;

const Button = styled(BaseButton)`
  width: fit-content;
  padding: 0 15px;
`;

const AlternateButton = styled(BaseAlternateButton)`
  width: fit-content;
  padding: 0 20px;
`;

const StoreGrid: React.FC<Props> = ({
  stores,
  startEditing,
  toggleVisibility,
}) => {
  return (
    <Container>
      <h2>Live Stores</h2>
      <Grid>
        {stores
          .filter(({ locked }) => !locked)
          .map((str) => (
            <StoreCard
              store={str}
              startEditing={startEditing}
              toggleVisibility={toggleVisibility}
            />
          ))}
      </Grid>{" "}
      <h2>Hidden Stores</h2>
      <Grid>
        {stores
          .filter(({ locked }) => locked === true)
          .map((str) => (
            <StoreCard
              store={str}
              startEditing={startEditing}
              toggleVisibility={toggleVisibility}
            />
          ))}
      </Grid>
    </Container>
  );
};

const OpenUrl = styled.img`
  max-width: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const StoreCard: React.FC<{
  store: Store;
  startEditing: Function;
  toggleVisibility: Function;
}> = ({
  store: { id, storeName, logoUrl, locked, departments },
  startEditing,
  toggleVisibility,
}) => {
  function isStoreCustom(deps?: Department[]) {
    if (!deps) return false;
    let numOfDeals = 0;

    deps.forEach(({ deals }) => (numOfDeals += deals.length));

    return !(numOfDeals > 0);
  }

  return (
    <CardContainer>
      <div className="side-by-side-flex" id="top-banner">
        <img src={logoUrl} id="logo-url" />
        {!locked && (
          <OpenUrl
            src="/assets/svg/link.svg"
            onClick={() =>
              window.open(
                `https://store.vouch.co.uk/${storeName
                  .replace(" ", "-")
                  .toLowerCase()}/${departments[0].departmentName}`,
                "_blank"
              )
            }
          />
        )}
      </div>
      <div>
        <h3>Departments</h3>
        <DepartmentContainer>
          {departments &&
            departments.map(({ departmentName }) => (
              <div id="department">{departmentName}</div>
            ))}
          {!departments && <p>No departments added yet!</p>}
        </DepartmentContainer>
      </div>

      <StoreControls>
        {isStoreCustom(departments) ? (
          <Button disabled>Not Editable</Button>
        ) : (
          <AlternateButton onClick={() => startEditing(id)}>
            Edit
          </AlternateButton>
        )}
        <Button onClick={() => toggleVisibility(id)}>
          Make {locked ? "Public" : "Private"}
        </Button>
      </StoreControls>
    </CardContainer>
  );
};

export default StoreGrid;
