import React, { useState, useEffect } from "react";
import "./defaults.css";
import styled from "styled-components";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// utils
import { isUserAuthorised } from "./utils/auth";
import ROUTES from "./utils/route-constants";
// types
import { IAccountInfo } from "./types/Msal";
import { Permissions } from "./types/Permissions";
// components
import Login from "./views/auth/Login";
import UnAuthorisedUser from "./views/auth/UnAuthorisedUser";
import Dashboard from "./views/auth/AuthDashboard";
import MenuAuthBar from "./views/auth/MenuAuthBar";
// services
import ShopBuilder from "./views/shop-builder/ShopBuilder";
import ActivityLogger from "./views/activity-logger/ActivityLoggerDashboard";

const AppContainer = styled.div`
  background: linear-gradient(168deg, #fff 33%, #e0ecf5);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50%;
  height: -webkit-fill-available;
  min-height: 100vh;
`;

function App() {
  const [authError, setAuthError] = useState(false);
  const [permissions, setPermissions] = useState<Permissions | null>(null);
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  async function handleLogin() {
    await instance.loginRedirect({
      state: ``,
      scopes: [`a8581991-dc2c-40ec-b42b-b8c0428c3e68`],
    });
  }

  async function handleLogout() {
    await instance.logout();
  }

  useEffect(() => {
    if (!account || permissions) return;

    const { username, idTokenClaims }: IAccountInfo = account;

    isUserAuthorised({
      email: username,
      userId: idTokenClaims.oid,
    }).then((data) => {
      if (data.permissions) {
        setPermissions(data.permissions);
        if (authError) {
          setAuthError(false);
        }
      } else {
        setAuthError(true);
      }
    });
  }, [account]);

  return (
    <AppContainer>
      <Router>
        {account && <MenuAuthBar handleLogout={handleLogout} />}
        {authError && <UnAuthorisedUser />}
        <Switch>
          <Route exact path="/">
            <Redirect to={ROUTES.DASHBOARD} />
          </Route>

          <Route path={ROUTES.DASHBOARD} exact>
            {account && permissions ? (
              <Dashboard permissions={permissions} />
            ) : (
              <Login handleLogin={handleLogin} />
            )}
          </Route>
          <Route path={ROUTES.SHOP_BUILDER} exact>
            <ShopBuilder />
          </Route>
          <Route path={ROUTES.ACTIVITY_LOGGER} exact>
            <ActivityLogger />
          </Route>
        </Switch>
      </Router>
    </AppContainer>
  );
}

export default App;
