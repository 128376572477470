import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface Props {
  type: string;
  label: string;
  onChange: Function;
  name?: string;
  required?: boolean;
  value: any;
  predictions: SearchPrediction[];
  handleSearch: Function;
  setSearchPredictions: Function;
  cancel: Function;
  handleUpdateSuggestions: Function;
}

interface SearchPrediction {
  text: string;
}

const TextInputContainer = styled.div<{ focused: boolean }>`
  margin: 10px 0;
  padding: 18px;
  position: relative;
  text-align: left;
  cursor: text;
  width: 100%;
  border: none;
`;

const TextInputElement = styled.input`
  margin-left: 25px;
  outline: none;
  border: none;
  background: none;
`;

const TextInputLabel = styled.span<{ focused: boolean }>`
  margin-left: 25px;
  position: absolute;
  color: ${(props) => (props.focused ? "#34bebd" : "#bcbec0")};
  transform: ${(props) =>
    props.focused ? "translateY(-29px) scale(0.71)" : ""};
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  cursor: text;
  white-space: nowrap;
  font-size: 14px;
  background: ${(props) => (props.focused ? "white" : "transparent")};
  padding: 2px 8px;
`;

const SearchPredictionsContainer = styled.div`
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  border-radius: 5px;
  background: white;
  position: absolute;
  top: 0;
  left: -5px;
  margin: 55px 0 0 5px;
  width: 100%;
  z-index: 1001;
  background: #f2f2f2;
  padding: 0 15px;

  div {
    background: white;
    border: 1px solid #f2f2f2;
    list-style: none;
    margin: 15px 5px;
    padding: 20px;
    border-radius: 10px;
  }

  div:hover {
    cursor: pointer;
    border-color: #34bebd;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  left: 0;
  top: 10px;
`;

const PredictiveTextInput: React.FC<Props> = ({
  label,
  onChange,
  name,
  required,
  type,
  value,
  handleSearch,
  predictions,
  setSearchPredictions,
  cancel,
  handleUpdateSuggestions,
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(document.createElement("input"));

  function handleBlur() {
    if (inputRef.current.value.trim() === "") {
      setFocused(false);
      setSearchPredictions([]);
    } else {
      setFocused(true);
    }
  }

  function handleKeyPressed(e: any) {
    if (e.key === "ArrowDown" && predictions.length > 0) {
      // focus on first prediction item
    }
  }

  function handleFocus() {
    // @ts-ignore
    inputRef.current.focus();
    setFocused(true);
  }

  useEffect(() => {
    //@ts-ignore
    if (inputRef.current.value.trim() !== "") {
      setFocused(true);
    }
  }, [value]);

  return (
    <TextInputContainer focused={focused} onClick={() => handleFocus()}>
      <TextInputLabel
        focused={focused}
        id="text-input"
        onClick={() => setFocused(true)}
        onBlur={() => handleBlur()}
      >
        {label}
      </TextInputLabel>
      <SearchIcon src="/assets/svg/search.svg" />
      <TextInputElement
        onKeyDown={(e: any) => handleKeyPressed(e)}
        type={type || "text"}
        name={name}
        required={required}
        value={value}
        ref={inputRef}
        onClick={() => setFocused(true)}
        onBlur={() => handleBlur()}
        onChange={(e) => {
          onChange(e);
          handleUpdateSuggestions();
        }}
        autoComplete="off"
      />
      <SearchPredictionsContainer>
        {predictions &&
          predictions.length > 0 &&
          predictions.map(({ text }, index) => (
            <div
              onClick={(e) => {
                handleUpdateSuggestions(text);
                handleSearch(text);
              }}
            >
              {text}
            </div>
          ))}
      </SearchPredictionsContainer>
    </TextInputContainer>
  );
};

export default PredictiveTextInput;
