import { useQuery } from "react-query";
import { gql, request } from "graphql-request";
import Env from "../Env";

export function useActivities() {
  return useQuery("activities", async () => {
    const data = await request(
      Env.agentEndpoint,
      gql`
        query (
          $dates: DateRange
          $subjectId: String
          $customerId: String
          $applicationId: String
        ) {
          getLastActivitiesBetweenXAndY(
            dates: $dates
            subjectId: $subjectId
            customerId: $customerId
            applicationId: $applicationId
          ) {
            subjectId
            applicationId
            customerId
            customerName
            id
            createdAt
            resourceType
            type
            source {
              type
              resource
              action
              metadata {
                logicAppRunId
              }
            }
            payload {
              message
              details
            }
          }
        }
      `
    );
    return data.getLastActivitiesBetweenXAndY;
  });
}
