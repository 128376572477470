import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { JSONSchema7 } from "json-schema";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";

interface Props {
  label: string;
  onChange: (val: string) => void;
  name?: string;
  required?: boolean;
  value: string;
  schema: JSONSchema7;
}

const TextInputContainer = styled.div<{ focused: boolean }>`
  margin: 10px 0;
  border-style: solid;
  border-color: rgba(188, 190, 192, 0.5);
  border-width: 1px;
  padding: 18px;
  border-radius: 5px;
  position: relative;
  text-align: left;
  cursor: text;
  border-color: ${(props) => (props.focused ? "#34bebd" : "")};
  max-width: 450px;
`;

const TextInputElement = styled.input`
  outline: none;
  border: none;
  background: none;
  width: 100%;
`;

const TextInputLabel = styled.span<{ focused: boolean }>`
  position: absolute;
  color: ${(props) => (props.focused ? "#34bebd" : "#bcbec0")};
  transform: ${(props) =>
    props.focused ? "translateY(-29px) scale(0.71)" : ""};
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  cursor: text;
  white-space: nowrap;
  font-size: 14px;
  background: ${(props) => (props.focused ? "white" : "transparent")};
  padding: 2px 8px;
`;

export function TextInput(props: Props) {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  function handleBlur() {
    //@ts-ignore
    if (inputRef.current.value.trim() === "") {
      setFocused(false);
    } else {
      setFocused(true);
    }
  }

  function handleFocus() {
    // @ts-ignore
    inputRef.current.focus();
    setFocused(true);
  }

  useEffect(() => {
    //@ts-ignore
    if (inputRef.current.value.trim() !== "") {
      setFocused(true);
    }
  }, []);

  function createLabel(text: string = ""): string {
    const result = text.replace(/([A-Z])/g, " $1");
    return `${result.charAt(0).toUpperCase()}${result.slice(1)}${
      props.required ? "*" : ""
    }`;
  }

  return (
    <>
      <p>{props.schema.description}</p>
      <TextInputContainer focused={focused} onClick={() => handleFocus()}>
        <TextInputLabel
          focused={focused}
          id="text-input"
          onClick={() => setFocused(true)}
          onFocus={() => handleFocus()}
          onBeforeInputCapture={() => handleFocus()}
          onBlur={() => handleBlur()}
        >
          {createLabel(props.label)}
        </TextInputLabel>
        <TextInputElement
          type="text"
          name={props.name}
          required={props.required}
          value={props.value}
          ref={inputRef}
          onClick={() => setFocused(true)}
          onBlur={() => handleBlur()}
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => handleFocus()}
          onBeforeInputCapture={() => handleFocus()}
          tabIndex={0}
        />
      </TextInputContainer>
    </>
  );
}

export default TextInput;
