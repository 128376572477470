import React from "react";
import styled from "styled-components";

interface Props {}

const Container = styled.div``;

const UnAuthorisedUser: React.FC<Props> = (props) => {
  return (
    <Container>
      <h1>Ooops, it looks like you don't have permission to do this.</h1>
      <p>
        You don't have to do anything else at the moment, we have contacted
        support on our end with your details so we will grant permission to this
        account if you should have access.
      </p>
    </Container>
  );
};

export default UnAuthorisedUser;
