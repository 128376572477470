import React, { useRef, useEffect } from "react";
import styled from "styled-components";

interface Props {
  id?: string;
}

const FooterContainer = styled.div`
  position: relative;
  z-index: 0 !important;
  height: "30px";
`;

const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  span {
    color: #34bebd;
  }

  h2 {
    color: #34bebd;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Emoji = styled.img`
  margin-left: 5px;
  max-width: 100px;
`;

const FooterChildren = styled.div``;

const Footer: React.FC<Props> = ({ children }) => {
  return (
    <FooterContainer>
      <Title onClick={(e) => (window.location.href = "/")}>
        <h2>
          powered by
          <Emoji src="/assets/svg/vouchlogo.svg" />
        </h2>
      </Title>
      <FooterChildren>{children}</FooterChildren>
    </FooterContainer>
  );
};

export default Footer;
