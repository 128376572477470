import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Button } from "../common/styles";
import { PillButton } from "vouch.ui.react.components";
import { isFile } from "../helpers/helpers";
import { getAllBlobs } from "../utils/storage";

interface Props {
  onSelectFile?: (fileName: string) => void;
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FolderItem = styled.div`
  display: grid;
  grid-template-rows: 50px auto 100px;
  #folder-icon {
    max-width: 24px;
    max-height: 24px;
    margin-right: 8px;
  }
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: #f2f2f2;
  }
`;

const ImagePreview = styled.img`
  width: 100%;
`;

const FolderList = styled.div`
  margin: 0 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-gap: 25px;
`;

const MediaLibrary: React.FC<Props> = ({ onSelectFile }) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const [folders, setFolders] = useState<any[]>([]);
  useEffect(() => {
    getAllBlobs().then((data) => {
      if (data && data.content) {
        setFolders(data.content);
      }
    });
  }, []);

  async function handleSubFolderSelect(subfolder: string) {
    const itemIsAFile = isFile(subfolder);

    // item selected is a file and onSelectFile funcion exists
    if (itemIsAFile && onSelectFile) {
      onSelectFile(subfolder);
      return;
    }
    // do nothing because item selected is a file
    if (itemIsAFile) return;

    // get a refreshed list of blobs because we are in a sub folder
    const subblobs = await getAllBlobs(subfolder);
    if (subblobs && subblobs.content) {
      setFolders(subblobs.content);
    }
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log(file);
    }
  };

  return (
    <Container>
      <div
        className="side-by-side-flex"
        style={{
          margin: "0 10px",
        }}
      >
        <h1>Media Library</h1>
        <div className="side-by-side-flex-end">
          <PillButton onClick={() => handleSubFolderSelect("")}>
            Back to home
          </PillButton>
          <span style={{ color: "white" }}>|</span>
          <PillButton>New Folder</PillButton>
          <span style={{ color: "white" }}>|</span>
          <PillButton
            onClick={() => {
              fileUploadRef.current?.click();
            }}
          >
            Upload File
          </PillButton>
        </div>
        <input
          type="file"
          hidden
          ref={fileUploadRef}
          onChange={handleFileUpload}
        />
      </div>
      <FolderList>
        {folders.map((folder) => {
          const isItemAFile = isFile(folder.name);
          return (
            <FolderItem onClick={() => handleSubFolderSelect(folder.name)}>
              <div className="side-by-side-flex">
                {isItemAFile ? (
                  <img src="/assets/svg/file.svg" id="folder-icon" />
                ) : (
                  <img src="/assets/svg/folder.svg" id="folder-icon" />
                )}
                <p>{folder.name}</p>
              </div>
              <ImagePreview
                src={`${process.env.REACT_APP_BLOB_PREVIEW}/${folder.name}`}
                alt=""
              />
              {isItemAFile && <Button>Use Image</Button>}
            </FolderItem>
          );
        })}
      </FolderList>
    </Container>
  );
};

export default MediaLibrary;
