import React from "react";
import { withTheme } from "@rjsf/core";
import styled from "styled-components";
//components
import {
  ArrayFieldTemplate,
  ToggleSwitch,
  TitleField,
} from "../components/input/Inputs";
import FileSelector from "./input/FileSelector";
import TextInput from "./input/RJSFTextInput";
import { Button } from "../common/styles";
import { useAccount, useMsal } from "@azure/msal-react";

interface Props {
  schema: any;
  data: any;
  setData: Function;
  setView: (view: "dashboard" | "builder") => void;
}

const theme = {
  widgets: {
    CheckboxWidget: ToggleSwitch,
    BaseInput: TextInput,
    fileSelector: FileSelector,
  },
  ArrayFieldTemplate: ArrayFieldTemplate,
  TitleFieldTemplate: TitleField,
  StringFieldTemplate: TextInput,
};

const ThemedForm = withTheme(theme);

const Container = styled.div`
  background: white;
  z-index: 100;
  position: relative;
  overflow-y: auto;
  text-align: center;
  max-width: 800px;
  margin: 25px auto;
  padding: 30px 20px;
  width: 100%;
  max-height: 100vh;
  border-radius: 10px;
  text-align: left;
`;

const uiSchema = {
  smallBanner: {
    "ui:widget": "fileSelector",
  },
  logoUrl: {
    "ui:widget": "fileSelector",
  },
  departments: {
    items: {
      smallBanner: {
        "ui:widget": "fileSelector",
      },

      departmentIcon: {
        "ui:widget": "fileSelector",
      },
      departmentBanner: {
        "ui:widget": "fileSelector",
      },
    },
  },
  icon: {
    "ui:widget": "fileSelector",
  },
};

const BuilderForm: React.FC<Props> = ({ schema, data, setData, setView }) => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  function formUpdate({ formData }: any) {
    setData(Object.assign({ ...data }, { ...formData }));
  }

  function createStore({ formData }: any) {
    const reqBody = {
      query: `mutation($formData: StoreInput!, $storeName: String!, $storeId: String!, $personId: String! ){
            updateStore(storeId: $storeId, storeName: $storeName, store: $formData, personId: $personId ){
              id
              storeName
            }
          }`,
      variables: {
        formData: { ...formData },
        storeName: formData.storeName,
        storeId: formData.id,
        personId: account?.username,
      },
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        setView("dashboard");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Container>
      <h2>{data.storeName}</h2>
      <h4>{`www.assistant.vouch.co.uk/${data.id}/${
        data.departments && data.departments.length > 0
          ? data.departments[0].departmentName.toLowerCase()
          : "test"
      }`}</h4>
      <ThemedForm
        formData={data}
        schema={schema}
        uiSchema={uiSchema}
        onChange={(rawData: any) => formUpdate(rawData)}
        onSubmit={(data: any) => createStore(data)}
      >
        <Button type="submit">Save Changes</Button>
      </ThemedForm>
    </Container>
  );
};

export default BuilderForm;
