import React from "react";
import styled from "styled-components";
import { AlternateButton, Button } from "../common/styles";

interface Props {
  setView: Function;
  setOpen: Function;
  view: string;
}

const HeaderContainer = styled.div`
  padding: 15px;
  background: #f2f2f2;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-weight: bold;
  border-radius: 10px;
  #highlight {
    color: #34bebd;
  }
`;

const BackArrow = styled.img`
  max-width: 25px;
  min-width: 25px;
  margin: 0 0 0 45px;

  &:hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  max-width: 120px;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header: React.FC<Props> = ({ setView, setOpen, view }) => {
  function handleClick() {
    if (view === "dashboard") {
      setOpen(true);
    } else if (view === "builder") {
      setOpen(false);
      setView("dashboard");
    }
  }
  return (
    <HeaderContainer>
      <SideBySide>
        <LogoContainer>
          <Logo src="/assets/logos/logo.svg" />
          {view === "builder" && (
            <BackArrow
              src="/assets/svg/back.svg"
              onClick={() => setView("dashboard")}
            />
          )}
        </LogoContainer>
        <div className="side-by-side-flex-end">
          {view === "dashboard" && (
            <AlternateButton onClick={() => setView("media")}>
              Media Library
            </AlternateButton>
          )}
          {view === "media" && (
            <AlternateButton onClick={() => setView("dashboard")}>
              Close
            </AlternateButton>
          )}
          {(view === "dashboard" || view === "media") && (
            <Button onClick={() => handleClick()}>New Store</Button>
          )}
        </div>
      </SideBySide>
    </HeaderContainer>
  );
};

export default Header;
