import React from "react";
import styled from "styled-components";

interface Props {
  storeLogo: string;
  department: string;
}

const MainHeader: React.FC<Props> = ({ storeLogo, department = "" }) => {
  const StoreLogo = styled.img`
    max-width: 110px;
    grid-area: logo;
    justify-self: center;
  `;

  const BackArrow = styled.img`
    max-width: 25px;
    min-width: 25px;
    margin: 0 20px 0 0;
  `;

  const Title = styled.div`
    grid-area: department;
    h1 {
      font-size: 3rem;
      font-family: "Montserrat", sans-serif;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
  `;

  const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "controls logo blank"
      "department department department";
    justify-content: stretch;
    width: 100%;
  `;

  const ControlsContainer = styled.div`
    grid-area: controls;
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    grid- img {
      max-width: 24px;
      height: auto;
    }

    img:hover {
      cursor: pointer;
    }
  `;

  const BannerContainer = styled.div`
    margin: 15px 0;
  `;

  return (
    <BannerContainer>
      <TitleContainer>
        <ControlsContainer>
          <BackArrow src="/assets/svg/back.svg" />

        </ControlsContainer>
        <StoreLogo src={storeLogo} />
        {department && (
          <Title>
            <h1>{department}</h1>
          </Title>
        )}
        <div style={{ gridArea: "blank" }}>&nbsp;</div>
      </TitleContainer>
    </BannerContainer>
  );
};

export default MainHeader;
