import React from "react";
import StoreSkeleton from "./StoreSkeleton";
import styled from "styled-components";

interface Props {
  schema: any;
  data: any;
}

const Container = styled.div`
  overflow: hidden;
`;

const BuilderPreview: React.FC<Props> = ({ schema, data }) => {
  return (
    <Container>
      <StoreSkeleton store={data} />
    </Container>
  );
};

export default BuilderPreview;
