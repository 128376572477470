import React, { useState } from "react";
import styled from "styled-components";
// views
import Dashboard from "./ShopBuilderDashboard";
import StoreCreator from "../../views/shop-builder/StoreCreator";
// components
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import NewShopForm from "../../components/NewShopForm";
import MediaLibrary from "../../components/MediaLibrary";

const AppContainer = styled.div`
  background: linear-gradient(168deg, #fff 33%, #e0ecf5);
  background-repeat: no-repeat;
  background-attachment: relative;
  background-position: 50%;
  min-height: 100vh;
  top: 60px;
  height: -webkit-fill-available;
`;

function ShopBuilder() {
  const [view, setView] = useState<"dashboard" | "builder" | "media">(
    "dashboard"
  ); // select view
  const [open, setOpen] = useState(false); // open and close new shop modal
  const [newStore, setNewStore] = useState({
    id: "",
    storeName: "",
  }); // hold new shop form entries in state
  const [editStore, setEditStore] = useState(null);

  function onStoreCreated() {
    setOpen(false);
  }

  return (
    <AppContainer>
      {open && (
        <Modal open={open} setOpen={setOpen}>
          <NewShopForm
            newStore={newStore}
            setNewStore={setNewStore}
            onStoreCreated={onStoreCreated}
          />
        </Modal>
      )}
      <Header setView={setView} setOpen={setOpen} view={view} />
      {view === "dashboard" && (
        <Dashboard setView={setView} setEditStore={setEditStore} />
      )}
      {view === "media" && <MediaLibrary />}

      {view === "builder" && (
        <StoreCreator editStore={editStore} setView={setView} />
      )}
    </AppContainer>
  );
}

export default ShopBuilder;
