import React, { useMemo } from "react";
import styled from "styled-components";
// components
import { PillButton } from "vouch.ui.react.components";
import { useHistory, useLocation } from "react-router-dom";
import ROUTES from "../../utils/route-constants";
interface Props {
  handleLogout: () => void;
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const MenuAuthBar: React.FC<Props> = ({ handleLogout }) => {
  const greeting = useMemo(() => {
    const hours = new Date().getHours();
    // TODO remove my name lol

    if (hours < 12) return "Good Morning, Casey";

    if (hours > 12 && hours < 18) return "Good Afternoon, Casey";

    if (hours > 18) return "Good Evening, Casey";

    return "Hey Casey";
  }, []);

  const history = useHistory();
  const location = useLocation();

  return (
    <Container>
      <h3>{greeting}</h3>
      <div className="side-by-side-flex-end">
        {location.pathname !== ROUTES.DASHBOARD && (
          <PillButton onClick={() => history.push(ROUTES.DASHBOARD)}>
            Dashboard
          </PillButton>
        )}
        <span style={{ color: "#fff" }}>{" | "}</span>
        <PillButton onClick={() => handleLogout()}>Logout</PillButton>
      </div>
    </Container>
  );
};

export default MenuAuthBar;
