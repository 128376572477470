import React from "react";
import styled from "styled-components";
// types
import { Permissions } from "../../types/Permissions";
// components
import { AlternateButton } from "../../common/styles";
import { Link } from "react-router-dom";
import ROUTES from "../../utils/route-constants";
interface Props {
  permissions: Permissions;
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const BannerContainer = styled(Container)`
  margin: 0 auto;
  width: 100%;

  img {
    width: 190px;
  }
`;

const ServiceContainer = styled(Container)`
  margin-top: -50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 15px;
`;

const ServiceCard = styled(Link)`
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 15px;
  background: #fff;
  &:hover {
    cursor: pointer;
  }
`;

const FullWidthBlurb = styled.div`
  background: #34bebd;
  color: white;
  padding: 100px 0px;

  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }

  h1 {
    color: white;
    font-size: 3rem;
  }
`;

const AuthDashboard: React.FC<Props> = (props) => {
  return (
    <>
      <FullWidthBlurb>
        <BannerContainer>
          <h1>
            <span style={{ color: "#082842" }}>Vouch</span> Utility Dashboard
          </h1>
          <h4 style={{ color: "#082842" }}>
            Your accessible services will be listed below.
          </h4>
        </BannerContainer>
      </FullWidthBlurb>
      <ServiceContainer>
        <ServiceCard to={ROUTES.SHOP_BUILDER}>
          <div className="side-by-side-flex">
            <h1>Shop Builder</h1>
            <img src="/assets/logos/residential.svg" alt="" />
          </div>
          <p>
            Update, create and change the visability of the stores accessible
            from the Vouch Home Move Assistant
          </p>
          <AlternateButton>Start Building</AlternateButton>
        </ServiceCard>
        <ServiceCard to={ROUTES.ACTIVITY_LOGGER}>
          <div className="side-by-side-flex">
            <h1>Activity Logger</h1>
            <img src="/assets/logos/report.svg" alt="" />
          </div>
          <p>
            View activities and their outcomes in real time which are being
            processed within the Vouch system.
          </p>
          <AlternateButton>View Logs</AlternateButton>
        </ServiceCard>
      </ServiceContainer>
    </>
  );
};

export default AuthDashboard;
