import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
// types
import PredictiveTextInput from "../common/PredictiveTextInput";
import { ActivityLog } from "../types/ActivityLogger";
const { SearchClient, AzureKeyCredential } = require("@azure/search-documents");

const SearchPanelContainer = styled.form`
  padding: 15px 0;
  border-radius: 5px;
  position: relative;
  margin-left: 100px;
  width: "800px";

  @media (max-width: 1300px) {
    margin-left: 0;
  }
`;

interface Props {
  searchTerm: string | null;
  setSearchTerm: (term: string) => void;
  searchResults: ActivityLog[];
  setSearchResults: (results: ActivityLog[]) => void;
}

const SearchPanel: React.FC<Props> = ({
  searchTerm,
  setSearchTerm,
  setSearchResults,
}) => {
  // Azure cognitive Search
  const [searchClient, setSearchClient] = useState<any>();
  const [searchPredictions, setSearchPredictions] = useState<any[]>([]);

  useEffect(() => {
    const client = new SearchClient(
      `${process.env.REACT_APP_SEARCHURL}`,
      `${process.env.REACT_APP_INDEX_NAME}`,
      new AzureKeyCredential(`${process.env.REACT_APP_SEARCHKEY}`)
    );

    setSearchClient(client);
  }, []);

  function handleUpdateSuggestions(text: string) {
    if (text) {
      setSearchTerm(text);
      return;
    }
    if (searchTerm?.trim() === "") {
      setSearchPredictions([]);
    }
    if (!searchClient || !searchTerm || searchTerm.trim() === "") return;
    handleAutoComplete();
  }

  async function handleAutoComplete() {
    const autoCompleteResults = await searchClient.autocomplete(
      searchTerm,
      "sg",
      {
        top: 5,
      }
    );

    setSearchPredictions([...autoCompleteResults.results]);
  }

  async function handleSearch(suggestedText: string = "") {
    let termToSearch = "";
    if (suggestedText.trim() !== "") {
      termToSearch = suggestedText;
    } else {
      termToSearch = `${searchTerm}`;
    }

    setSearchPredictions([]);
    if (searchClient && searchClient !== null) {
      const searchResults = await searchClient.search(`${termToSearch}*`, {
        top: 15,
      });
      const foundResults = [];
      for await (const result of searchResults.results) {
        const data = result.document;
        foundResults.push(data);
      }
      setSearchResults(foundResults);
    }
  }

  return (
    <SearchPanelContainer
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <div></div>
      <div className="side-by-side-flex">
        <PredictiveTextInput
          type="text"
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
          label="Search"
          name="search"
          predictions={searchPredictions}
          handleSearch={handleSearch}
          handleUpdateSuggestions={handleUpdateSuggestions}
          setSearchPredictions={setSearchPredictions}
          cancel={() => {}}
        />
      </div>
    </SearchPanelContainer>
  );
};

export default SearchPanel;
