const Env: EnvVaraibles = {
  agentEndpoint: `${process.env.REACT_APP_AGENT_ENDPOINT}`,
  shopEndpoint: `${process.env.REACT_APP_SHOP_ENDPOINT}`,
  cdnPreview: `${process.env.REACT_APP_BLOB_PREVIEW}`,
};

interface EnvVaraibles {
  agentEndpoint: string;
  shopEndpoint: string;
  cdnPreview: string;
}

export default Env;
