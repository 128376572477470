import React from "react"
import { Button, AlternateButton } from "../../common/styles"
import styled from "styled-components"

const BtnSpan = styled.span`
    text-transform: capitalize;

`

export function ArrayFieldTemplate(props: any) {
    return (
        <div>
            {props.items.map((element: any) => element.children)}
            {props.canAdd && <AlternateButton alternate type="button" onClick={props.onAddClick}>Add <BtnSpan>{props.title.slice(0, -1)}</BtnSpan> </AlternateButton>}
        </div>
    );
}

export function TitleField(props: any) {
    console.log(props)
    return (
        <div>
            <h1>Woo</h1>
        </div>
    );
}

export function ToggleSwitch(props: any) {
    return (
        <button id="custom" className={props.value ? "checked" : "unchecked"} onClick={() => props.onChange(!props.value)}>
            {String(props.value)}
        </button>
    );
};