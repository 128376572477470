import React, { useState } from "react";
import styled from "styled-components";
// components
import BuilderForm from "../../components/BuilderForm";
import BuilderPreview from "../../components/BuilderPreview";
// data
const schema = require("../../data/schema.json");

const BuilderContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 7fr;
  overflow-y: none;
  max-height: 100vh;
  grid-gap: 10px;
`;

interface Props {
  editStore: any;
  setView: (view: "dashboard" | "builder") => void;
}

const StoreCreator: React.FC<Props> = ({ editStore, setView }) => {
  const [data, setData] = useState(editStore);

  return (
    <BuilderContainer>
      <BuilderForm
        schema={schema}
        data={data}
        setData={setData}
        setView={setView}
      />
      <BuilderPreview schema={schema} data={data} />
    </BuilderContainer>
  );
};

export default StoreCreator;
