export interface IUserInput {
  email: string;
  userId: string;
}

export async function isUserAuthorised({ email, userId }: IUserInput) {
  const res = await fetch(`${process.env.REACT_APP_SIGN_IN}`, {
    method: "POST",
    headers: {
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      email,
      userId,
    }),
  });

  const outcome = await res.json();

  return outcome;
}
